$icon-font-path: '../fonts/bootstrap/';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';

$icon-font-path: '../fonts/iconic/';
@import './components/iconic-bootstrap.scss';

$icon-font-path: '../fonts/icomoon/';
@import './components/icomoon.scss';

@import '../../node_modules/bourbon/app/assets/stylesheets/_bourbon.scss';

$blue: #06a7e2;
$black: #1b1b1b;
$white: #ffffff;
$dark-gray: #575757;
$light-gray: #cfd4d8;
$lighter-gray: #eeeeee;
$red: red;

$constrained-width: 1024px;
$top-nav-height: 64px;

body {
  font-family: 'Helvetica', sans-serif;
  font-weight: lighter;
}

a {
  cursor: pointer;
}

.main-view {
  font-size: 1.6rem;
  font-weight: normal;
}

.ng-invalid.message {
  color: $red;
  font-weight: bold;
  font-size: 1.2rem;
}
.input-group.ng-invalid {
  border-radius: 3px;
  border: solid 1px $red;
  i {
    color: $red;
  }
  input {
    // @include border-right-radius(3px);
    // height: 3.5rem;
    // border-left: 0;
    &,
    &:focus {
      @include box-shadow(none);
      border-color: $light-gray;
    }
  }
}

h1 {
  font-size: 2.4rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.8rem;
}

.section-header {
  padding-bottom: 6rem;
  margin-bottom: 2rem;
  margin-top: 4rem;
  line-height: 3rem;
  font-size: 1.4rem;
  position: relative;
  @media (min-width: $screen-sm-min) {
    padding-bottom: 4rem;
  }
  h1 {
    font-weight: bold;
    margin-top: -0.3rem;
    margin-bottom: 0;
    font-size: 2.4rem;
    @media (min-width: $screen-sm-min) {
      position: absolute;
    }
  }
  &:after {
    content: '';
    border-bottom: solid 2px $dark-gray;
    display: block;
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
  }
  &.no-link {
    @media (max-width: $screen-sm-min) {
      padding-bottom: 1rem;
    }
  }
}
.an {
  font-family: 'Aaux Next', sans-serif;
}

.large-text {
  font-size: 2rem;
}

.inline-form {
  line-height: 34px;
  input[type='radio'],
  input[type='checkbox'] {
    margin-top: 10px;
  }
  label {
    @media (min-width: $screen-sm-min) {
      text-align: right;
    }
    font-size: 1.2rem;
  }
  select {
    height: 4rem;
    line-height: 4rem;
  }
  label.radio-inline,
  label.checkbox-inline {
    text-align: left;
  }
  .tight > div {
    padding-left: 2px;
    padding-right: 2px;
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

.account-form {
  font-size: 1.4rem;
}

.radio-inline,
.checkbox-inline {
  &.no-margin {
    margin: 0;
  }
  &.no-padding-left {
    padding-left: 0;
  }
}

.with-banner {
  padding-top: 1px;
  @media (min-width: $screen-sm-min) {
    padding-top: 5rem;
  }
}

.btn {
  &.btn-white {
    background-color: $white;
    color: $dark-gray;
  }
  background-color: $blue;
  text-transform: uppercase;
  border: solid 3px $light-gray;
  color: $white;
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 1.6rem;
  border-radius: 2rem;
  &:hover {
    background-color: lighten($blue, 20);
    border: solid 3px $light-gray;
    color: $white;
  }
}

.profile-label {
  font-weight: bold;
  font-size: 1.6rem;
  margin-top: 1rem;
}

.profile-icon-wrapper {
  margin-bottom: 5rem;
  .profile-icon-content {
    padding-left: 86px;
    padding-right: 10px;
    text-align: center;
  }
  &.profile-icon-wrapper-workout-summary .profile-icon-content {
    padding-top: 2.2rem;
    font-weight: bold;
    font-size: 2.4rem;
  }
  &.profile-icon-wrapper-users .profile-icon-content {
    @media (min-width: $screen-md-min) {
      padding-top: 2.2rem;
    }
    font-weight: bold;
    font-size: 1.6rem;
    a {
      display: block;
      font-weight: normal;
      font-size: 1.3rem;
    }
  }
  .profile-icon {
    float: left;
    width: 76px;
    text-align: center;
    padding-top: 81px;
    position: relative;
    text-transform: uppercase;
    font-size: 1.2rem;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      display: block;
      width: 76px;
      height: 76px;
      background-size: cover;
    }
    &.profile-icon-workouts:before {
      background-image: url(../img/icon-workouts.png);
    }
    &.profile-icon-distance:before {
      background-image: url(../img/icon-workouts.png);
    }
    &.profile-icon-time:before {
      background-image: url(../img/icon-workouts.png);
    }
    &.profile-icon-calories:before {
      background-image: url(../img/icon-workouts.png);
    }
    &.profile-icon-user:before {
      background-image: url(../img/icon-user.png);
    }
    &.profile-icon-no-user:before {
      background-image: url(../img/icon-no-user.png);
    }
  }
}

.notice {
  background-color: $black;
  color: $light-gray;
  // padding: 3rem 10rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.6rem;
  .close-notice-button {
    float: right;
    margin-top: -2rem;
    margin-right: 1rem;
    a {
      color: $light-gray;
    }
    color: $light-gray;
  }
  .viewfit-link-button {
    padding-top: 3rem;
    @media (min-width: $screen-sm-min) {
      padding-top: 0;
    }
  }
}

.footer-nav {
  margin-top: 10rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: $black;
  color: $light-gray;
  font-size: 1.4rem;
  text-align: center;
  a {
    color: $white;
    text-decoration: none;
  }
  .viafit-logo a {
    background: url(../img/logo-viafit-white.png) no-repeat center;
  }
  .viewfit-logo a {
    background: url(../img/logo-viewfit-white.png) no-repeat center;
  }
  .logo a {
    display: block;
    height: 10rem;
    width: 15rem;
    margin: 0 auto;
    background-size: 10rem auto;
    span {
      display: none;
    }
  }
  ul {
    li {
      padding: 0;
      a {
        display: block;
        padding: 0 0.7rem;
        border-left: solid 2px $white;
        @media (min-width: $screen-sm-min) {
          padding: 0 2rem;
        }
        color: $light-gray !important;
      }
      &:first-child a {
        border-left: 0;
      }
    }
  }
}

.top-nav {
  position: relative;
  a {
    text-decoration: none;
  }
  .sphere {
    position: relative;
    &:before {
      content: '';
      background: url(../img/icon-world.png) no-repeat center center;
      width: 32px;
      top: -25px;
      left: -40px;
      height: $top-nav-height;
      position: absolute;
    }
  }
  .logo {
    @media (max-width: 535px) {
      float: none !important;
      margin-bottom: 10px;
    }
  }
  .logo a {
    display: block;
    background-position: center;
    width: 105px;
    height: $top-nav-height;
    margin: 0 auto;
    @media (min-width: $screen-sm-min) {
      background-position: left;
    }
    span {
      display: none;
    }
  }
  .viafit-logo a {
    background-image: url(../img/logo-viafit.png);
    background-repeat: no-repeat;
  }
  .viewfit-logo a {
    background-image: url(../img/logo-viewfit.png);
    background-repeat: no-repeat;
  }
  .right-menu {
    @media (min-width: $screen-xs-min) {
      float: right;
    }
  }
  .right-menu > span > a {
    text-align: center;
    line-height: $top-nav-height;
    display: inline-block;
    width: 100%;
    padding-left: 2rem;
    color: $blue;
    font-size: 1.3rem;
    text-transform: uppercase;
    @media (min-width: $screen-xs-min) {
      width: 23rem;
      font-size: 1.6rem;
    }
  }
  .button {
    a {
      text-decoration: none;
      span {
        padding: 1rem;
        border: solid 1px $blue;
        border-radius: 2rem;
      }
      &:hover span {
        color: $white;
        background-color: $blue;
      }
    }
  }

  .menu-dropdown {
    position: inherit;
    width: 50%;
    display: inline-block;
    float: left;
    @media (min-width: $screen-sm-min) {
      float: none;
      width: auto;
      display: inline;
    }
    .menu-content {
      display: none;
      position: absolute;
      right: 0;
      background-color: $blue;
      color: $white;
      z-index: 1000;
      @include border-bottom-radius(1rem);
      padding: 1rem 0 1rem 0;
      & a {
        color: $white;
        text-decoration: underline;
      }
    }
    @media (min-width: $screen-sm-min) {
      .menu-content {
        border-bottom-right-radius: 0rem;
      }
    }

    &.open .menu-content {
      display: block;
    }
  }

  .languagemenu-dropdown {
    .menu-content {
      a {
        display: block;
        padding: 0.7rem 0;
        font-size: 1.6rem;
        text-align: center;
        @media (min-width: $screen-sm-min) {
          text-align: left;
        }
      }
    }
  }

  .usermenu-dropdown {
    .xid-logo {
      margin-bottom: 2rem;
      span {
        background-image: url(../img/logo-xid.png);
        width: 83px;
        height: 42px;
        background-size: cover;
        display: block;
        text-indent: -999em;
      }
    }
    > a {
      background-color: $blue;
      color: $white !important;
    }
    .usermenu-content {
      .list-group {
        color: $dark-gray;
        .list-group-item {
          padding: 0;
          border-color: $light-gray;
          border-left-width: 3px;
          border-right-width: 3px;
          border-bottom-width: 3px;
          &:last-child {
            @include border-bottom-radius(5px);
          }
          &:first-child {
            border-top-width: 3px;
            @include border-top-radius(5px);
          }
        }
        a {
          text-transform: uppercase;
          font-size: 1.6rem;
          font-weight: normal;
          display: block;
          padding: 0.7rem 1rem;
          color: $dark-gray;
          text-decoration: none;
          &:hover {
            color: $blue;
          }
        }
      }
    }
    .form-control {
      @include box-shadow(none);
    }
    .input-group {
      border: solid 3px $light-gray;
      border-radius: 6px;
      &.ng-invalid {
        border-color: $red;
        i {
          color: $red;
        }
      }
      span {
        background-color: $white;
        color: $light-gray;
      }
      input {
        @include border-right-radius(3px);
        height: 3.5rem;
        border-left: 0;
        &,
        &:focus {
          @include box-shadow(none);
          border-color: $light-gray;
        }
      }
    }
    select {
      border: solid 3px $light-gray;
      height: 4rem;
      line-height: 4rem;
    }
  }
}

.wifi-progress-panels {
  i {
    font-size: 9rem;
    margin-bottom: 2rem;
    display: block;
    text-align: center;
    color: $dark-gray;
  }
  .panel-title {
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 2rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }

  .panel-description {
    font-size: 1.6rem;
    padding-left: 2.2rem;
    padding-right: 2.2rem;
  }

  &.current-progress-account .progress-account,
  &.current-progress-activate .progress-activate,
  &.current-progress-connect .progress-connect,
  &.current-progress-next-steps .progress-next-steps {
    i {
      color: $blue;
    }
  }

  &.current-progress-activate .progress-account,
  &.current-progress-connect .progress-account,
  &.current-progress-connect .progress-activate,
  &.current-progress-create .progress-account,
  &.current-progress-create .progress-activate,
  &.current-progress-create .progress-connect {
    i,
    .panel-title,
    .panel-description {
      color: $light-gray;
    }
  }
}

.wifi-progress-next-steps,
.wifi-progress-account {
  i {
    font-size: 9rem;
    margin-bottom: 2rem;
    display: block;
    text-align: center;
    color: $blue;
  }
  h1 {
    margin-top: 3rem;
    margin-bottom: 6rem;
    text-align: center;
    font-size: 5rem;
    font-weight: 200;
  }
  h2 {
    font-size: 2.6rem;
  }
  .connect-track-share-icons {
    margin-top: 3rem;
    margin-left: 0.1rem;
  }
}

.parallax-banner {
  .parallax-banner-background {
    @media (min-width: $screen-sm-min) {
      height: 300px;
      background: url(../img/banner-default.jpg) center top no-repeat;
    }
    @media (min-width: 1506px) {
      background-size: 100% auto;
    }
  }
  &.support .parallax-banner-background {
    background: url(../img/banner-support.jpg) center no-repeat;
  }
  &.profile .parallax-banner-background,
  &.user_workouts .parallax-banner-background {
    background: url(../img/banner-profile.jpg) center no-repeat;
  }
  &.create .parallax-banner-background {
    background: url(../img/banner-create.jpg) center no-repeat;
  }
  &.wifi .parallax-banner-background {
    background: url(../img/banner-connect.jpg) center top no-repeat;
  }
  &.custom .parallax-banner-background {
    @media (min-width: 1805px) {
      background-size: cover;
    }
  }
  .constrained {
    @media (min-width: $screen-sm-min) {
      margin-top: -4.6rem;
    }
  }
  .parallax-banner-label {
    padding: 0 1rem;
    background-color: $black;
    text-transform: uppercase;
    color: $white;
    font-size: 2.6rem;
    line-height: 4.6rem;
    height: 4.6rem;
    @media (min-width: $screen-sm-min) {
      display: inline-block;
      background-color: $blue;
      @include border-top-radius(0.3rem);
      bottom: 0px;
    }
  }
}

.connect-track-share-icons {
  > div {
    @media (max-width: $screen-md-min) and (min-width: $screen-sm-max) {
      //border: 1px solid hotpink;
      text-align: center;
    }
  }
  .connect-track-share-description {
    text-align: center;
    text-transform: lowercase;
    font-size: 4rem;
    letter-spacing: 0.9rem;
    @media (min-width: $screen-md-min) {
      float: left;
      //text-align: right;
      line-height: 11rem;
    }
  }
  .connect-track-share-icons-wrapper {
    text-align: center;
    margin-bottom: 3rem;
    @media (min-width: $screen-md-min) {
      float: right;
      //text-align: left;
      margin-bottom: 0;
    }
  }
  .connect-track-share-icon {
    display: inline-block;
    text-transform: lowercase;
    text-align: center;
    padding-top: 5.5rem;
    width: 7rem;
    opacity: 0.4;

    &.plus {
      width: 10px;
    }
    &.connect {
      padding-right: 1.2rem;
      background: top url(../img/logo-connect.png) no-repeat;
      background-size: 91px 61px;
    }
    &.track {
      background: top url(../img/logo-track.png) no-repeat;
      background-size: 91px 61px;
    }
    &.share {
      background: top url(../img/logo-share.png) no-repeat;
      background-size: 91px 61px;
    }
  }
  &.connect .connect {
    opacity: 1;
  }
  &.track .track {
    opacity: 1;
  }
  &.share .share {
    opacity: 1;
  }
  &.blue {
    .connect-track-share-icon {
      opacity: 1;
      &.connect {
        background-image: url(../img/logo-connect-blue.png);
      }
      &.track {
        background-image: url(../img/logo-track-blue.png);
      }
      &.share {
        background-image: url(../img/logo-share-blue.png);
      }
    }
    color: $blue !important;
    .connect,
    .track,
    .share {
      opacity: 1;
    }
  }
}

.connect-background {
  background: -320px top url(../img/bg-connect.jpg) no-repeat #262524;

  @media (min-width: $screen-sm-min) {
    background-position: center top;
  }
}

.dot-background {
  background: no-repeat center top url(../img/bg-dot-pattern.png);
}

.wood-background {
  background: repeat-x center top url(../img/bg-wood.jpg);
}

.blue-grid-background {
  background: url(../img/bg-blue-pattern.jpg);
}

.constrained {
  margin: 0 auto;
  max-width: $constrained-width;
}

.learn-more-about {
  margin-top: 4rem;
  text-align: center;

  a {
    display: block;
    margin-top: 0.5rem;
  }
}

.left-panel {
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: right;
  }
}

.right-panel {
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: left;
  }
}

.home-connect-1 {
  color: $white;
  padding-top: 1px;
  @media (min-width: $screen-sm-min) {
    padding-bottom: 0;
  }
  .constrained {
    min-height: 604px;
  }

  .viewfit-logo-white {
    background: url(../img/logo-viewfit-white.png) center no-repeat;
  }

  .viafit-logo-white {
    background: url(../img/logo-viafit-white.png) center no-repeat;
  }

  .logo-white {
    height: 166px;
    margin-top: 2rem;
    @media (min-width: $screen-sm-min) {
      margin-top: 10rem;
      background-position: center;
    }
  }

  .connect-track-share-icons {
    @media (min-width: $screen-sm-min) {
      margin-top: 17rem;
    }
  }

  .learn-more-about {
    margin-bottom: 3rem;
  }
}

.home-connect-2 {
  color: $black;
  padding: 4rem;

  .full-width {
    width: 100%;
  }

  .small {
    font-size: 60%;
    line-height: 1.5;
  }

  .text-gray {
    color: #cfd4d8;
  }

  .title-col {
    padding-bottom: 4rem;

    h1 {
      margin-top: 3rem;
      margin-bottom: 6rem;
      text-align: center;
      font-size: 5rem;
      font-weight: 200;
    }
  }

  .two-col {
    padding-top: 4rem;

    h2 {
      font-size: 2.6rem;
      line-height: 2;
    }

    .what-it-does-description {
      line-height: 2;
    }

    .constrained {
      max-width: 503px;
    }
  }
}

.computer-background {
  height: 600px;
  display: block;
  background-repeat: no-repeat;
  @media (max-width: 767px) {
    background: none !important;
  }
  @media (min-width: 767px) {
    background-position: 375px 28%;
  }
  @media (min-width: 1024px) {
    background-position: 600px 35px;
  }
  @media (min-width: 1399px) {
    background-position: 825px 35px;
  }
}

.viafit-computer-background {
  background-image: url(../img/bg-computer-viafit.png);
}

.viewfit-computer-background {
  background-image: url(../img/bg-computer-viewfit.png);
}
// 767, 1023, 1399

.home-track {
  color: $white;
  background-color: #c19459;
  @media (min-width: $screen-sm-min) {
    min-height: 600px;
  }
  .connect-track-share-icons {
    padding-top: 15rem;
  }
}

.home-share {
  color: $white;
  background-color: $blue;
  padding-top: 4rem;
  padding-bottom: 1rem;
  @media (min-width: $screen-sm-min) {
    padding-top: 1rem;
  }

  .constrained {
    min-height: 422px;
  }

  .app-col {
    height: 115px;
    margin-top: 3rem;
    margin-bottom: 3rem;

    .caption {
      @extend .text-center;

      h3 {
        text-transform: uppercase;
        font-size: 1.5rem;
      }
    }
  }

  .disclaimer {
    margin-top: 3rem;
    margin-bottom: 3rem;

    p {
      text-align: center;
      font-size: 1.5rem;
      font-weight: 200;
    }
  }

  .connect-track-share-icons {
    padding-top: 4rem;
  }
  /*
    .viewfit-share-animation {
        background: url(../img/animation-viewfit.gif) left no-repeat;
    }

    .viafit-share-animation {
        background: url(../img/animation-viafit.gif) left no-repeat;
    }
*/
  .share-animation {
    height: 422px;

    @media (min-width: $screen-sm-min) {
      background-position: right;
    }
  }
}

.support-faq {
  .section-header {
    margin-bottom: 2.2rem;
    font-size: 1.4rem;
    position: relative;
    h1 {
      font-weight: bold;
      margin-top: -0.3rem;
      margin-bottom: 0;
      font-size: 2rem;
    }
    &:after {
      content: '';
      border-bottom: solid 2px $dark-gray;
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  h2 {
    font-size: 1.8rem;
  }
  h3 {
    font-size: 1.6rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    a {
      text-decoration: none;
    }
  }
  li ul {
    margin-bottom: 3rem;
    li {
      list-style: disc;
      margin-bottom: 0.5rem;
    }
  }
}

.home-whats-next {
  padding: 4rem;

  .constrained {
    min-height: 300px;
  }

  i {
    font-size: 9rem;
    margin-bottom: 2rem;
    display: block;
    text-align: center;
    color: $blue;
  }

  h1 {
    margin-top: 3rem;
    margin-bottom: 6rem;
    text-align: center;
    font-size: 5rem;
    font-weight: 200;
  }

  .why-description {
    line-height: 2;
  }

  h2 {
    font-size: 2.6rem;
  }

  .connect-track-share-icons {
    margin-top: 3rem;
    margin-left: 0.1rem;
  }
}

.video-background-container {
  position: relative;
  overflow: hidden;
  video {
    position: absolute;
    z-index: 1;
    transform-origin: 0% 0%;
    width: 100%;
  }
  .video-background-inner {
    z-index: 10;
  }
}

.check-for-touchscreen {
  .modal-header {
    border-bottom: 0;
  }
  .modal-content {
    p {
      line-height: 2em;
    }

    @media (min-width: $screen-sm-min) {
      background: url(../img/bg-touchscreen-modal.jpg) no-repeat top center;
      padding-right: 30rem;
    }

    @media (min-width: $screen-md-min) {
      background: url(../img/bg-touchscreen-modal.jpg) no-repeat top center;
      padding-right: 50rem;
    }
  }
}

.soft-top {
  padding-top: 2em;
}
.soft-bottom {
  padding-bottom: 2em;
}

.flush-top {
  margin-top: 0;
}

// VF-470 Hack to fix tos link in Firefox
.tos-link {
  z-index: 1000;
}

.loading-spinner {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;

  &:after {
    content: '';
    width: 50px;
    height: 50px;
    border: 10px solid #dddddd;
    border-top-color: #06a6e2;
    border-radius: 50%;
    transform: rotate(0.16turn);
    animation: loading 1s ease infinite;
  }

  &-register {
    margin-left: 83px;
  }
  &-login {
    &:after {
      border-top-color: #72c3e2;
    }
    margin-left: 45px;
  }
}

@keyframes loading {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.privacy-policy {
  .tos-title {
    font-weight: bold;
  }
}
