$icon-font-key: "x8fogn";

@font-face {
    font-family: 'icomoon';
    src:url('#{$icon-font-path}icomoon.eot?#{$icon-font-key}');
    src:url('#{$icon-font-path}icomoon.eot?#iefix#{$icon-font-key}') format('embedded-opentype'),
        url('#{$icon-font-path}icomoon.woff?#{$icon-font-key}') format('woff'),
        url('#{$icon-font-path}icomoon.ttf?#{$icon-font-key}') format('truetype'),
        url('#{$icon-font-path}icomoon.svg?#{$icon-font-key}#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-profile:before {
    content: "\e604";
}
.icon-key:before {
    content: "\e602";
}
.icon-connection:before {
    content: "\e605";
}
.icon-switch:before {
    content: "\e606";
}
.icon-menu:before {
    content: "\e608";
}
.icon-thumbs-up:before {
    content: "\e607";
}
.icon-mail:before {
    content: "\e600";
}
.icon-arrow-down:before {
    content: "\e603";
}
.icon-arrow-down2:before {
    content: "\e601";
}
